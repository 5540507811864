import React from 'react'
import * as Styled from './SectionBgImageText.styles'
import { Column, Row } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import Button from '../Button'

const SectionBgImageText = ({
  title,
  smallTitle,
  lead,
  content,
  narrowContent,
  bgImage,
  page,
  pageText,
  bgLight,
  reverseLarge,
}) => {
  return (
    <Styled.Section
      Tag="section"
      image={bgImage}
      bgLight={bgLight}
      narrowContent={narrowContent}
      reverseLarge={reverseLarge}
    >
      <Row alignMiddle alignRight={reverseLarge}>
        <Column xs={12} lg={7} xl={6}>
          <Styled.ContentWrapper>
            <DoubleTitle smallTitle={smallTitle} title={title} border="addon" />
            <p className="lead">{lead}</p>
            {content && <p>{content}</p>}
            {page && (
              <Row column>
                <Button primary page={page}>
                  {pageText}
                </Button>
              </Row>
            )}
          </Styled.ContentWrapper>
        </Column>
      </Row>
    </Styled.Section>
  )
}

export default SectionBgImageText
