import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: 24px 0;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 40px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 60px 0;
  }

  h2 + .lead {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      max-width: 66.6%;
      margin: 0 auto 1rem;
    }
  }

  .swiper-slide {
    .lead {
      text-transform: uppercase;
      margin: 16px 0;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        margin-top: 32px;
        min-height: 64px;
      }

      & + p {
        max-width: 350px;
        margin: 0 auto 1rem;
      }
    }

    svg {
      height: 70px;
      width: auto;
      margin-top: 16px;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        margin-top: 40px;
      }
    }
  }

  .swiper {
    & + p,
    & + a {
      margin-top: 16px;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        margin-top: 40px;
      }
    }
  }
`

export { Section }
