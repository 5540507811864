import styled, { css } from 'styled-components'
import { BgImage } from 'gbimage-bridge'

const Section = styled(BgImage)`
  position: relative;
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding-top: 100%;
    background-size: contain !important;
    background-position: top !important;

    &:before,
    &:after {
      background-size: contain !important;
      background-position: top !important;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    &:before,
    &:after {
      background-position: left !important;
    }

    & > div {
      min-height: 100vh;
    }
  }

  ${props =>
    props.reverseLarge === true &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        &:before,
        &:after {
          background-position: right !important;
        }
      }
    `}

  ${props =>
    props.bgLight &&
    css`
      background-color: ${props.theme.colors.light};
    `}

  ${props =>
    props.narrowContent &&
    css`
      @media (min-width: ${props.theme.breakpoints.xxlUp}) {
        p {
          max-width: 85%;
        }
      }
    `}
`

const ContentWrapper = styled.div`
  padding: 60px 0 48px;

  a {
    margin-top: 20px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-top: 40px;
    }
  }

  .list {
    li {
      display: inline-block;
      width: 100%;
      position: relative;
      padding: 0 0 8px 32px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 14px;
        height: 10px;
        background: transparent
          url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOS40OTciIGhlaWdodD0iMTQuNjAxIiB2aWV3Qm94PSIwIDAgMTkuNDk3IDE0LjYwMSI+CiAgPHBhdGggZD0iTTEwMy40NzcsMTQ2LjA1MmMtLjIxNi4yNTgtLjQ0My41MTEtLjY4NC43NTItMi4yNSwyLjI1Ni05LjAyNiw4Ljk4MS05LjAyNiw4Ljk4MXMtMi40OTEtMi40NzItNC45NTMtNC45MjFsLTIuNDIzLDIuNDE4YzMuNDMzLDMuNDQyLDcuMzc2LDcuMzcxLDcuMzc2LDcuMzcxczMuMTQxLTMuMTMxLDYuMjYxLTYuMjU0YzIuMDQ4LTIuMDUsNC4wODktNC4xLDUuMjE2LTUuMjQ2LjIyMy0uMjI3LjQzNi0uNDY0LjY0NC0uN1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04Ni4zOTEgLTE0Ni4wNTIpIiBmaWxsPSIjNDI2ZDliIi8+Cjwvc3ZnPgo=')
          no-repeat center / contain;

        @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
          width: 20px;
          height: 15px;
        }
      }
    }
  }
`

export { Section, ContentWrapper }
