import React, { useRef } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Row } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import Button from '../Button'
import * as StyledSwiper from '../Swiper/Swiper.styles'
import * as Styled from './SectionIconsSlider.styles'

// Install modules
SwiperCore.use([Navigation])

const SectionIconsSlider = ({
  title,
  smallTitle,
  content,
  secondContent,
  button,
  items,
}) => {
  const swiper = useRef()

  return (
    <Styled.Section>
      <Row column>
        <DoubleTitle
          smallTitle={smallTitle}
          title={title}
          border="addon"
          center
        />
        {content}
        <Swiper
          className="mobile-disabled"
          spaceBetween={0}
          slidesPerView={1}
          noSwiping
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
          }}
          wrapperTag="ul"
          ref={swiper}
        >
          {items.map((item, index) => (
            <SwiperSlide tag="li" key={index}>
              {item.icon}
              <p className="lead">{item.title}</p>
              <p>{item.content}</p>
            </SwiperSlide>
          ))}
        </Swiper>
        {button && (
          <Button page="/o-nas" primary>
            Więcej o nas
          </Button>
        )}
        {secondContent || null}
      </Row>
      <StyledSwiper.CustomPrevButton
        onClick={() => swiper.current.swiper.slidePrev()}
        type="button"
        aria-label="Poprzedni slajd"
        centered
        hideForMobile
      />
      <StyledSwiper.CustomNextButton
        onClick={() => swiper.current.swiper.slideNext()}
        type="button"
        aria-label="Następny slajd"
        centered
        hideForMobile
      />
    </Styled.Section>
  )
}

export default SectionIconsSlider
